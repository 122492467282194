.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

.transition(@style: all; @time: 0.3s; @easing: ease-out) {

		-webkit-transition: @style @time @easing;
  	   -moz-transition: @style @time @easing;
	     -o-transition: @style @time @easing;
            transition: @style @time @easing;
		
}

.scale(@zoom) {
	-webkit-transform: scale(@zoom);
    -moz-transform: scale(@zoom);
    -ms-transform: scale(@zoom);
    transform: scale(@zoom);
}

.ni(@bg: #000) {
	
	
	
}