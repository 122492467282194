.modal.fade.animateUp {
	background-color:transparent;
	top:0;
	opacity:1;
	.transition(all, 0.15s, linear);
}

.modal.fade.animateUp.in {
	//background-color:rgba(255,255,255,0.9);
	top:0;
	opacity:1;
	.transition(all, 0.15s, linear);
}

.modal.fade.animateUp .modal-dialog {

    opacity: 0.75;
    
   -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
   
    .transition(all, 0.3s, ease-out);
    
}

.modal.fade.animateUp.in .modal-dialog {

  -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
	
	opacity: 1;
  
    .transition(all, 0.3s, ease-out);
}

.celink {
	display:block;
	.celement {
		opacity:1;
		//.transition(all, 0.1s, ease-out);
	}
	&.loading {
		
		background:transparent url(/img/loading.gif) center center no-repeat;
		background-size: 24px 24px;
		.celement {
			opacity:0.25;
			//.transition(all, 0.1s, ease-out);
		}
		
	}
}
