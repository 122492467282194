.panel-collapse-store  .panel-title {
	cursor:pointer;
}
.panel-collapse-store .panel-title:after {
	float:right;
	font-family: 'FontAwesome';
	//content:'\f146';
	content:'\f147';
	
}
.panel-collapse-store .panel-title.collapsed:after {
	//content:'\f0fe';
	content:'\f196';
	
}
#notesPanelBody .panel-body {
	padding:0;
}
#notesText {
	box-sizing: border-box;
	display:block;
	border:none;
	background:#ffe;
	width:100%;
	min-width:100%;
	max-width:100%;
	padding:15px;
	min-height:51px;
	max-height:408px;
	overflow-x: hidden;
}
#notesText:focus {
	outline: none;
}

.panel-heading .pull-right {
	
	.btn-sm {
		margin-top:-4px;
		padding:4px 6px 4px 4px;
		line-height:1.3;
	}
	
	
} 


/*
.panel-heading a.btn-sm.btn-link {
	//background:@orange;
	//color:@white;
	margin-top:-4px;
	//margin-right:-4px;
	
	padding:4px 7px;
	border:1px solid @borderbrown;
	//background:lighten(@mainColor,50);
	background:@lightbrown;
	color:@gray-light;
	border:1px solid @borderColor;
	background:@lightestgrey;
}
.panel-heading a.btn-sm.btn-link:hover {
	text-decoration: none;
	color:@gray-light;
	border:1px solid @gray-light;
	}
*/