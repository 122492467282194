
/* ************* Sidebar ********************* */

.sidebar-left {
	position:fixed;
	left:0;
	//overflow: hidden;
	top:50px;
	bottom:0;
	 background-color: #222d32;
	background-color:#272727;
	
	background-color:@sidebar-left-bgcolor;
	//overflow-y: scroll;
	overflow-x: visible;
	
	z-index:1031;
}

.navbar-hide {
	
	@time: 0.1s;
	@ease: cubic-bezier(0.165, 0.840, 0.440, 1.000);	
	
	.toggle-navbar .fa-outdent {
		display:none;
	}
	
	
	.navbar-default a.navbar-brand {
	
	}
	
	.sidebar-left, .sidebar-left .sidebar-nav,.sidebar-left .sidebar-nav ul li, .navbar-default a.navbar-brand {		
		.transition(width; @time; @ease);
		width:@sidebar-left-hide-width;
	}
	.main h2.page-header,.main h1.page-header,.breadcrumb,.fixed-tabs {
		left:@sidebar-left-hide-width; 
		.transition(left; @time; @ease);
	}
	.main {
		margin-left:@sidebar-left-hide-width;
		.transition(all; @time; @ease);
	
	}
	
}
.navbar-show {
	
	@time: 0.1s;
	@ease: linear;	
	
	.toggle-navbar .fa-indent {
		display:none;
	}
	
	.sidebar-left,.sidebar-left .sidebar-nav,.sidebar-left .sidebar-nav ul li, .navbar-default a.navbar-brand {
		width:@sidebar-left-width;
		.transition(width; @time; @ease);
	}
	.main h2.page-header,.main h1.page-header,.main .breadcrumb,.fixed-tabs {
		left:@sidebar-left-width; 
		.transition(left; @time; @ease);
	}
	.main {
		margin-left:@sidebar-left-width;
		.transition(all; @time; @ease)
	}


}

.sidebar-left {

	.sidebar-nav {
	    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	    overflow:hidden;
	}
	.sidebar-nav ul {
	    list-style: none outside none;
	    margin: 0;
	    padding: 0;
	    
	}
	.sidebar-nav ul li, .sidebar-nav ul a {
	    display: block;
	    outline: 0 none;
	    white-space: nowrap;
	}
	.sidebar-nav ul li {
		width:@sidebar-left-width;
	}
	
	.sidebar-nav ul ul li.active a {
		//color:lighten(@sidebar-left-bgcolor,25);
		color:@gray;
		background-color:@body-background;
		text-shadow: 0 1px 1px #fff;
		border-top:1px solid @body-background;
	}
	
	.sidebar-nav ul a {
	    border-top: 1px solid rgba(0, 0, 0, 0.3);
	    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
	    color: @gray-light;
	    padding: 10px 15px;
	    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
		-webkit-transition: all 0.3s ease-out;
	  	   -moz-transition: all 0.3s ease-out;
		     -o-transition: all 0.3s ease-out;
	            transition: all 0.3s ease-out;
	   //width:(@sidebar-left-width - 40);
	    
	    i {
		    margin:0 10px 0 3px;
	    }
	    
	}
	.sidebar-nav ul a:hover, .sidebar-nav ul a:focus, .sidebar-nav ul a:active {
	    //color: #fff;
	    text-decoration: none;
	}
	.sidebar-nav ul ul a {
	    background-color: rgba(255, 255, 255, 0.1);
	    //padding: 10px 20px;
	}
	.sidebar-nav ul ul a:hover, .sidebar-nav ul ul a:focus, .sidebar-nav ul ul a:active,  .sidebar-nav ul ul a.active {
	    background-color: rgba(255, 255, 255, 0.2);
	}
	
	
	
	.sidebar-nav-item {
	    padding-left: 5px;
	}
	.sidebar-nav-item-icon {
	    padding-right: 15px;
	}
	.sidebar-nav .disabled i, .sidebar-nav .disabled span{
		color: #787878;
	}
	.sidebar-nav .disabled .badge{
		color: #FFF;
	}

} // End Sidebar Left