/* Deafult NavBAR */
.navbar-default {
	
	border-bottom:1px solid @border-color;	
	//z-index:1;
	
	a.navbar-brand {
		
		.logo-icon {
			height:20px;
			display:inline-block;
			vertical-align: middle;
			margin-right:12px;
			opacity:0.25;
			margin:-1px 13px 0 0px;
		} 

		width:@sidebar-left-width;
		background-color:lighten(@sidebar-left-bgcolor,5);

		color:@gray;
		height:50px;
		//text-align: center;
		white-space: nowrap;
		overflow:hidden;
		
	}
	
	a.navbar-brand:hover {
		background: @sidebar-left-bgcolor;
		.logo-icon {
			//opacity:1;
		}		
	}
		  
	.nav .open > a {
		border-color:@border-color !important;
		//background:#f80;
	}
	


}
#navbar {
	
}

.navbar-collapse .navbar-nav > li > a ,.navbar-collapse .navbar-nav > li > .active a,.navbar-collapse .navbar-nav > li > .active a:hover {
	//border-right:none;
	//color:@darkbrown;
}

.navbar-default .navbar-right > li > a ,.navbar-default .navbar-right > li > .active a,.navbar-default .navbar-right > li > .active a:hover {
	//border-right:0 !important;
	//border-left:1px solid @borderbrown !important;
}

.navbar-collapse .navbar-right > li > a ,.navbar-collapse .navbar-right > li > .active a,.navbar-collapse .navbar-right > li > .active a:hover {
	//border-left:none;
	//color:@darkbrown;
}



.navbar-default .active > a ,.navbar-default .active > a:hover,
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus
 {
	//background-color: #fbfbfb !important;
	//background-color: @darkbrown !important;
	//color:@lightbrown !important;
	background-color: @border-color;
}
.navbar-default a:hover {
	background-color: @border-color;
	//color:darken(@grey,3) !important;
}

.navbar-fixed-top {
	//z-index:1029;
	//left:50px;
}
.container-fluid > .navbar-collapse {
	//margin-left:50px;
}

.navbar-default {
	
	background-color: #fff;	
	border-color:@border-color;
	//z-index:9999;
	.navbar-form {
		padding:0 10px;	
	}
	
    .navbar-nav > li > a , .navbar-nav > li > .active a, .navbar-nav > li > .active a:hover {
		//border-left:0 !important;
		//border-right:1px solid @borderbrown !important;
		//color:@darkbrown;
		color:@gray;
	}
	
	.navbar-search  {
		border-right:1px solid @border-color;
		border-left:1px solid @border-color;
	}
	.in .navbar-search  {
		border-right:none;
		border-left:none;
	}
	
	.navbar-left > li > a ,.navbar-left > li > .active a, .navbar-left > li > .active a:hover {
		border-right:1px solid @border-color;	
	//	border-left:1px solid lighten(@borderbrown,15);	
	}
	
	.navbar-right > li > a ,.navbar-right > li > .active a, .navbar-right > li > .active a:hover {
		border-left:1px solid @border-color;	
	//	border-right:1px solid lighten(@borderbrown,20);	
	//	border-right:1px solid @borderbrown;	
	//	border-left:1px solid lighten(@borderbrown,15);	
	
	}
	
	
	.in {
	
		.navbar-left > li > a ,.navbar-left > li > .active a, .navbar-left > li > .active a:hover {
			border-right:none;	
		}
		
		.navbar-right > li > a ,.navbar-right > li > .active a, .navbar-right > li > .active a:hover {
			border-left:none;	
		}
		
		.navbar-nav {
			margin:0;
		}
		
	}
	
	
	.form-control, .btn-default {
		//border-color:@border-color;	
		//color:@darkbrown;
	}
	
	.form-control:focus {
		//box-shadow:0 0 4px lighten(@darkbrown,50);
	}
	.btn-default {
		//background-color:@lightbrown;
	}
	.btn-default:hover {
		//background-color:lighten(@lightbrown,3);
	}
	.navbar-collapse.in, .navbar-collapse.in .navbar-form {
		border-color:transparent;
	}
	.in .navbar-form {
		padding:0 15px;	
	}
	.navbar-toggle {
		//border-color:@borderbrown;
		//color:@darkbrown;
		background:#fff;
		.icon-bar {
			//background-color: @borderbrown;
		}
		
	}
	.navbar-toggle:hover {
			//background:@borderbrown;
			.icon-bar {
				background-color: #fff;
			}
		}
		
		
	.breadcrumb {
		margin-bottom:0;
		background-color: transparent;
		padding:0px;
		li {
			//display:inline-block;
			float:left;
		}
		li a,li.active span {
			//background:#f00;
			display:inline-block;
			padding:14px;
			height:50px;
			color:@gray;
		}
		li.active span {
			font-weight: bold;
		}
		
		li a:hover {
			text-decoration: none;
			color:@gray-darker;
			//background-color:@gray-lighter;
			background-color:transparent;
		}
	}
	.breadcrumb li:first-child, .breadcrumb li:first-child + li::before   {
		display:none;
	}
	.breadcrumb > li + li::before {
		//display:inline-block;
	    float:left;
	    display:inline-block;
	    color: @gray-lighter;
	    content: "/";
	    padding: 1rem 0.5rem;
	    font-size:2rem;
	    
	}
	
}
.navbar-toggle {
		
		
		
	}