/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar,.sidebar-right {
  /*display: none;*/
  float:none;
  clear:both;
}

.sidebar-right .toggle {
	//display:none;
}

//@time:0.3s;
//@ease:ease-in-out;

@time: 0.1s;
@ease: cubic-bezier(0.165, 0.840, 0.440, 1.000);
 
.sidebar-show .main {
  //margin-right:25%;
  width:auto; 
  margin-right:@sidebar-right-width;
  .transition(all; @time; @ease);
}

.sidebar-hide .main {
  width:auto; 
  margin-right:11px;
  //right:10px;
  .transition(all; @time; @ease);
  
}


.sidebar-show .sidebar-right {
  //width:25%; 
  width:@sidebar-right-width;
  right:0;
  overflow: visible;
  
  .transition(right; @time; @ease);
}

.sidebar-hide .sidebar-right {
 //width:10px; 
  width:@sidebar-right-width;
 overflow:hidden; 
 //right:-40px;
 right:-(@sidebar-right-width - @sidebar-right-bar-width - 1);
 overflow: visible;
 .transition(right; @time; @ease);
}

.sidebar-show .sidebar-right .toggle {
	right: (@sidebar-right-width - @sidebar-right-bar-width - 1);
	  .transition(right; @time; @ease);
}

.sidebar-hide .sidebar-right .toggle {
	right:0;
	  .transition(right; @time; @ease);
}


// Toggles

.sidebar-right .toggle i.center:before {
	content:'\f054';
}
.sidebar-hide .sidebar-right .toggle i.center:before {
	content:'\f053';
}
.sidebar-right .toggle i.top:before {
	content:'\f00d';
	content:'\f0c9';
}
.sidebar-hide .sidebar-right .toggle i.top:before {
	content:'\f0c9';
}



.sidebar ,.sidebar-right {
  
clear:none;
position: fixed;
top: 51px;
bottom: 0;
left: 0;
z-index: 1000;
display: block;
padding: 20px;
overflow-x: hidden;
overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
background-color: #fff;
border-right: 1px solid #ddd;

}
.sidebar-right {

left: auto;
right:0;
padding:0;
background-color: #fbfbfb;
border-right: none;
border-left: 1px solid #ddd;
padding: 20px 20px 20px 30px;
//overflow-x: visible !important;

//overflow-y: scroll !important; /* Scrollable contents if viewport is shorter than content. */
//overflow-x: visible;

overflow: visible;


	.scroll-pane {
		position:relative;
		margin:-20px -20px -20px -20px;
		padding:20px 20px 0px 20px;
		height:100%;
		height: calc(~"100% + 40px");
		//background:#f00;
		overflow:auto;
		overflow-y:scroll;
		box-sizing: border-box;
	}

}

.sidebar-right .toggle {
  display: block;
  top: 51px;
  margin-left:-30px;
position:fixed;

z-index:9998;
background: #f4f4f4;
border-right:1px solid #ddd;
width:10px;
bottom:0;	  
cursor:pointer;
box-shadow: 0px 0px 2px rgba(0,0,0,0.0625) inset;
overflow:visible;

}



.sidebar-right .toggle i.top {
	font-size:18px;
	line-height:17px;
	padding-top:10px;
	padding-left:4px;
	height:36px;
	color:#bbb;
	//border-top:1px solid #ddd;
	background: #f4f4f4;
	//box-shadow: 0px 0px 2px rgba(0,0,0,0.0625) inset;
	//border-bottom:1px solid #ddd;
	border-left:1px solid #ddd;
	position: absolute;
	z-index:9999 !important;
	//top:2;
	margin-top:0px;
	margin-left:-26px;
	width:28px;
	display:none;
} 
.sidebar-right .toggle:hover i.top {
	background:#eee;
	color:@gray-dark;
	}
.sidebar-right .toggle i.center {
	font-size:8px;
	line-height:30px;
	color:#bbb;
	border-top:1px solid #ddd;
	border-bottom:1px solid #ddd;
	border-left:1px solid #ddd;
	position: absolute;
	top:50%;
	margin-top:-17px;
	margin-left:-20px;
	width:30px;
	//background: #f4f4f4;
	background-color: #fbfbfb;
	
	border-radius: 3px 0 0 3px;
} 
.sidebar-right .toggle:hover i.center {
	color:@gray-dark;
	}
.sidebar-right .toggle:hover {

	background:#eee;

}

