.page-header.fixed{
    position: fixed;
    top: 50px;
    right: 11px;
    padding: 20px;
    background-color: #f8f8f8;
    z-index: 1000;
    opacity: 0.9;
}
.main h2.page-header.fixed, .main h1.page-header.fixed {
	border-bottom: 1px solid #ddd;
}

.page-header.fixed.no-tabs{
    top: 51px;
}

.sidebar-show .page-header.fixed{
    right: 300px;
}

.page-header.fixed .page-header-content{
    position: relative;
}

.page-header.fixed + .page-container{
    margin-top: 60px;
}
