.checkbox {

	&.highlight {

		// padding: 4px;

		& + .checkbox.highlight {
			margin-top: -5px;
		}

		& label.checkbox-custom {
			padding: 4px 4px 4px 24px;
			//margin-left: -8px;
		}

		&.checked label.checkbox-custom {
			background: #e9e9e9;
			border-radius: @baseBorderRadius;
		}
	}

	/* for keyboard tabbing */
	input[type="checkbox"]:focus + .checkbox-label,
	input[type="checkbox"]:hover + .checkbox-label,
	.checkbox-label:hover
	 {
		color: @grayLight;
	}

	input[type="checkbox"]:disabled:focus + .checkbox-label,
	input[type="checkbox"]:disabled:hover + .checkbox-label,
	&.disabled label:hover
	 {
		color: inherit;
	}

}

.form-horizontal .checkbox-inline {
	padding-top: 0;
}

.input-group-addon.checkbox-custom.checkbox-inline:before {
	left: 11px;
	top: 9px;
}

.checkbox-custom {
	position: relative;

	&:before {
		.iconBaseStyles("Glyphicons Halflings");
		border: 1px solid #adadad;
		border-radius: @baseBorderRadius;
		color: #fff;
		content: " ";
		font-size: 9px;
		height: 14px;
		left: 0px;
		padding: 1px 0 0 1px;
		position: absolute;
		top: 3px;
		width: 14px;
	}

	/* for keyboard tabbing */
	&:active:before, &:focus:before {
		color: @text-color;
		color: rgb(91, 157, 217);
		cursor: pointer;
		box-shadow: inset 0px 0px 2px 1px rgb(91, 157, 217), 0px 0px 5px 0px rgb(91, 157, 217);
	}

	&.highlight {
		&:before {
			left: 4px;
			top: 6px;
		}
	}

	&.checked {
		&:before {
			//background: #39b3d7;
			//border-color: #39b3d7;
			background: @checkbox-radio-color;
			border-color: @checkbox-radio-color;
			content: "\e013";
		}
	}

	&.disabled {
		cursor: not-allowed;

		&:before
		{
			cursor: not-allowed;
			opacity: .5;
		}
	}

	&.checkbox-inline {
		&:before {
			left: 0;
			top: 3px;
		}

		&.highlight {
			padding: 4px 4px 4px 24px;
			left: -4px;

			&:before {
				left: 4px;
				top: 7px;
			}

			&.checked {
				background: #e9e9e9;
				border-radius: @baseBorderRadius;
			}
		}
	}


	/* for keyboard tabbing */
	input[type="checkbox"]:focus + .checkbox-label {
		color: @grayLight;
	}



}

label.checkbox-custom.checkbox-inline {
	padding-left: 20px;
}