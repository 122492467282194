.sidebar-hide .main .inbox .email-content .email-header, .sidebar-hide .main .inbox .email-content .email-body, .sidebar-hide .main .inbox .email-content .email-attachments {
	left:0;
	right:0;
}
.sidebar-hide .main .inbox .email-content {
	right:0;
}

.main {
	
	// INBOX
	
	.row.inbox {
		//background:#f80;
		position: absolute;
		//position: relative;
		//bottom:0;
		right:0;
		//top:80px;
		//border-top:1px solid @border-color;
	}
	
	.inbox .email-mailbox, .inbox .email-list, .inbox .email-content {
		position: absolute;
		top:0;
	}
	.inbox .email-content .email-header, .inbox .email-content .email-body, .inbox .email-content .email-attachments {
		position: absolute;
		left:0;
		right:0;
		width:auto;
	}
	
	.inbox .email-mailbox {
	    left: 0;
	    width: 15%;
	}
	.inbox .email-list {
	    border-left: 1px solid #ddd;
	    border-right: 1px solid #ddd;
	    left: 15%;
	    overflow-y: scroll;
	    width: 35%;
	}
	.inbox .email-list.empty {
		width:85%;
	}
	.inbox .email-content .email-header {
		top:0;
		left:0;
		right:0;
		width:auto;
		border-top:1px solid @border-color;
	}

	 .inbox .email-content {
		 left:50%;
		 width:auto;
		 right:0;
		 bottom:0;
		 //background: #0ff;
		 margin-right: 0;
	 }
	.inbox .email-content .email-body  {
		top:130px;
				left:0;
		right:0;
		width:auto;
	}
}