// Twitter Bootstrap
	
	//@import "../vendor/bootstrap/less/bootstrap-min"; 	// No GlyphIcons
	//@import "../vendor/bootstrap/less/variables";   	// Var Hack
	@import "../../../vendor/bootstrap/less/bootstrap";   	// With Glyphicons ... Needed by some BS3-Plugins :-(
	
	//@import "../lib/custom-bootstrap/less/bootstrap";   	// Custom Bootstrap linked to vendor/bootstrap with custom variables.less
	//@import "../vendor/bootstrap/less/theme";			// Bootstrap Theme
	
	@breakPoint-sm : @screen-sm;
	@breakPoint-md : @screen-md;
	
	@import "../../../vendor/bootstrap-select/less/bootstrap-select";
	
	@import "../../../vendor/jasny-bootstrap/less/build/jasny-bootstrap";
	
	@import (inline) "../../../vendor/bootstrap-tabs-x/css/bootstrap-tabs-x.min.css";
	
	
	
	//@import (inline) "../vendor/fuelux/dist/css/fuelux.min.css";
	//@import "../vendor/fuelux/less/fuelux";
	//@import "../vendor/fuelux/less/checkbox-no-js";
	//@import "../vendor/fuelux/less/radio-no-js";
	@import "../../../vendor/fuelux/less/variables";
	@import "../../../vendor/fuelux/less/mixins";
	@import "../../../vendor/fuelux/less/icons";
	
	@import "../../../vendor/fuelux/less/forms";
	@import "../../../lib/fuelux/less/checkbox"; // added @checkbox-radio-color
	@import "../../../lib/fuelux/less/radio";
	@import "../../../vendor/fuelux/less/spinbox";
	
	.spinbox .spinbox-up {
	    padding: 0 0 14px 2px;
	    top: 2px;
	}
	.spinbox .spinbox-down {
	    //border-bottom-left-radius: 4px;
	    height: 16px;
	    padding: 0 0 8px 4px;
	    top: 2px;
	}
	
	@import (inline) "../../../vendor/intl-tel-input/build/css/intlTelInput.css";
	.intl-tel-input { width:100%; }

// AdminLTE

	//@import "../vendor/AdminLTE/build/less/AdminLTE";
	
// Bootstrap-Calendar
	
	//@import (inline) "../vendor/bootstrap-calendar/css/calendar.min.css";

// Bootswatch
	
	//@import "../vendor/bootswatch/spacelab/bootswatch";
	//@import "../vendor/bootswatch/spacelab/variables";
		
// Icons

	@import "../../../vendor/font-awesome/less/font-awesome"; 

// webfonts

	//@import "../vendor/roboto-fontface/css/roboto-fontface";
	//@import "../vendor/fontface-source-sans-pro/less/source-sans-pro";
	//@import "../vendor/gidole-fontface/less/gidole";
	//@import (inline) "../vendor/gidole-fontface/css/gidole.css";

// Lightbox
	
	@import (inline) "../../../vendor/magnific-popup/dist/magnific-popup.css";
	
	.image-link {
		cursor: -webkit-zoom-in; cursor: -moz-zoom-in;
	}
	
// Toastr

	@import "../../../vendor/toastr/toastr";
