html {
    overflow: hidden;
    height: 100%;
}

body {
	
	height: 100%;
    overflow: auto;
	padding-top: 50px;
	
  	background-color:@body-background;
  	
}

body, label, .checkbox label {
	font-weight: @btn-font-weight;
}

body > .container-fluid {
	min-height:100%;
	
}
body > .container-fluid > .row  {
	
	background: #f8f;
	height:100%;
	display:block;
	}
	
.main {

  position:relative;
  padding: 30px;
  height: 100%;
  overflow-y: scroll;

  
}

.main .page-header {
  margin-top: 0;

}

.tabs-above.tab-bordered .tab-content {
  background:#fff;
  border-radius: 0px;
}


.main h2.page-header,.main h1.page-header {
	  font-size:3rem;
	  border-bottom:none;
	}



.main .breadcrumb { 
	display:none;
}

// Fixed Breadcrumbs
/*
.main .breadcrumb {
  //background-color: #fff;
  box-shadow: 0 1px 4px rgba(0,0,0,0.06);
  position:fixed;
  top:51px;
  right:0;
  z-index:99;
  border-bottom:1px solid @borderColor;
  overflow:hidden;
  overflow-x: auto;
  white-space: nowrap;
  display:none;
}

.main .breadcrumb li {
  text-overflow: ellipsis;
  }
*/