.main {
	
	.controlbar-left {
		top:0;
		bottom:0;
		height:inherit;
		left:0;
		width:@controlbar-left-width;
		position:absolute;
		background-color: @body-background;
		color:inherit;
		border-right: 1px solid @border-color;
		padding:20px;
		overflow-y: scroll;
		
	}
	
	.content.contents, .content.pages {
		position: absolute;
		overflow-y: scroll;
		background-color: #fff;
		top:50px;
		left:@controlbar-left-width;
		right:0;
		bottom:0;
		padding:30px;
		margin-left: 0;
	}
	
	.content.pages {
		top:0;	
		
	}
	
	.uk-slider {
  	min-height:50px;
  	display:block;
	}
	
	.controlbar-top.contents, .controlbar-sub.contents {
	    background-color: #545c68;
	    height: 50px;
	    margin-left: 0;
	    position: absolute;
	    top: 0;
	    right:0;
	    width: auto;
	    overflow-x: scroll;
	    overflow-y: hidden;
	    z-index: 1000;
	    left:@controlbar-left-width;
	   /* ul {
		    white-space: nowrap;
		    width:auto;
		    display:block;
		    li {
		    	display:inline-block;
				float:none;
				white-space: nowrap;
				overflow: hidden; 
				  text-overflow: ellipsis;
				 
          }
	    }*/
	}
	
	.controlbar-sub.contents {
	    background-color: #747c88;
/*
	    display: none;
	    height: 50px;
	    margin-left: 200px;
	    position: fixed;
	    top: 104px;
	    z-index: 1001;
*/
	}
	
	.page-builder {
		padding:0 10px 10px 30px;
		min-height:200px;
	}
	
	.placeholder {
		
		float:none;
		
		.placeholder-handle {
		
			//opacity:1;
			
			.move {
				top:50%;
				margin-top:-10px;
				left:2px;
			}
			
			.add.add-down, .add.add-up {
				position: static;
				display:block;
				width:20px;
				line-height:25px;
				padding:0;
				text-align:center;
				font-size: 1.25rem;
			}
			
			.dropdown {
				position:absolute;
				width:20px;
				height:25px;
				left:0;
				text-align: center;
				
			}
			
			.dropdown.upper {
				top:0;	
			}
			.dropdown.lower {
				bottom:0;	
			}
		
		}
		
		.celement {
			
			padding:1rem 1.5rem;
			
			h1 {
				font-size:3rem;
			}
			h1,p {
				margin:0 0 1rem 0;
			}		
			
		}
		
	}
	
}

