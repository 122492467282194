 .placeholder-fixed-tabs {
	 height:80px;
	 clear:both;
 }
 
 .fixed-tabs {
	z-index:9;
	position:fixed;
	bottom:0;
	left:@sidebar-left-width; right:@sidebar-right-width;; 
	background:#fff;
	//background: rgba(255, 255, 255, 0.9);
	
	box-shadow: 0 1px 2px rgba(0,0,0,0.125) inset, 0 0 20px 10px @lightestgrey; 
	
	padding-bottom:20px;
	 
	 .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
		 
		 background:@lightestgrey;
		 
	 }
	 .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
		 
		background:@lightestgrey;

		 
	 }
	
	.tabs-below > .nav-tabs > li > a {
    	border-radius: 0;
	}
	

 } 
 .fixed-tabs .tabs-x .nav-tabs {
	padding:0 40px;	 
}

.sidebar-hide {

	.fixed-tabs {
	   right:10px;
	}
	  
}